import {get, post, put, del} from '../../utils/request'

/**
 * 初始化添加视频
 */
export function initAddVideo(params){
    return get(
        '/video/mobile/initAddVideo',
        params,
    )
}
/**
 * 初始化解析抖音视频
 */
export function parseDouYinVideoUrl(params){
    return post(
        "/video/initParseDouYinVideoUrl",
        params
    )
}
/**
 * 添加视频-解析抖音链接
 */
export function addVideoByParseUrl(params){
    return post(
        "/video/mobile/addVideoByParseUrl",
        params
    )
}

/**
 * 获取待编辑视频信息(移动端)
 */
export function getVideoDetailForEdit(params){
    return get(
        '/video/mobile/getVideoDetailForEdit',
        params,
    )
}

/**
 * 创建视频
 */
export function createVideo(params){
    return post(
        '/video/mobile/addVideo',
        params,
    )
}

/**
 * 编辑视频
 */
export function editVideo(params){
    return put(
        '/video/mobile/editVideo',
        params,
    )
}

/**
 * 获取视频列表
 */
export function getVideoList(params){
    return get(
        '/video/mobile/searchVideo',
        params,
    )
}

/**
 * 获取我的视频列表
 */
export function getMyVideoList(params) {
    return get(
        '/video/mobile/searchMyVideo',
        params,
    )
}

/**
 * 获取视频详情
 */
export function getVideoDetail(params) {
    return get(
        "/video/mobile/getVideoDetail",
        params
    )
}

/**
 * 根据分页详情获取视频详情
 */
export function getVideoDetailByPage(params) {
    return get(
        "/video/mobile/getVideoDetailByPage",
        params
    )
}

/**
 * 记录用户分享视频信息(移动端)
 */
export function recordUserVideoShareInfo(params) {
    return post(
        "/video/mobile/recordUserVideoShareInfo",
        params
    )
}

/**
 * 更新浏览次数
 */
export function updateViewsNumber(params) {
    return put(
        "/video/wechatApplet/updateViewsNumber",
        params
    )
}

/**
 * 删除我的视频信息
 */
export function deleteUserVideoShareInfo(params) {
    return del(
        "/video/mobile/deleteUserVideoShareInfo",
        params
    )
}

/**
 * 初始化搜索视频列表
 */
export function initSearchVideo(params) {
    return get (
        "/video/mobile/initSearchVideo",
        params
    )
}

/**
 * 点赞
 */
export function doThumbsUp(params) {
    return put(
        "/video/mobile/doThumbsUp",
        params
    )
}

/**
 * 取消点赞
 */
export function cancelThumbsUp(params) {
    return put(
        "/video/mobile/cancelThumbsUp",
        params
    )
}

/**
 * 获取留言
 */
export function getVideoDetailsUrl(vdId){
    return get(
        `/video/getVideoDetailsUrl/2/${vdId}`,
    )
}

export function getVideoClueInfoList(params){
    return get(
        `/video/mobile/getVideoClueInfoList`,
        params
    )
}


