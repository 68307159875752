<template>
  <div class="container-allvideo">
    <van-list>
      <div class="video-list">
        <van-cell v-for="(item, index) in videoList" :key="item.vdId">
          <div class="video-container">
            <div class="video-content">
              <div class="video-cover" @click="loadVideo(item.vdId)">
                <!--                              <img  src="/img/test/videosample.png"/>-->
                <img :src="item.videoCoverUrl?fileHost + item.videoCoverUrl:'/img/jpg/videoListDefaultCover.jpg'"
                     style="object-fit: contain"
                />
                <div class="labelContainer">
                  <span class="has-top" v-if="item.hasTop == 1">置顶</span>
                  <span
                    class="has-material-library"
                    v-if="myVideoListTag && item.hasMaterialLibrary == 1"
                    >素材库</span
                  >
                </div>
              </div>
              <div class="name">
                <span>{{ item.name }}</span>
                <!--分享按钮 -->
                <!--                                <svg  class="icon-button" @click="loadShow(item.vdId)">-->
                <!--                                    <use xlink:href="/img/svg/video.svg#icon-share"></use>-->
                <!--                                </svg>-->
<!--                <img-->
<!--                  src="/img/icons/share-2.jpg"-->
<!--                  class="icon-button"-->
<!--                  @click="loadShow(item)"-->
<!--                />-->
                <icon-svg iconClass="iconshare-2" @click="loadShow(item)" class="icon-button" />
              </div>
              <div class="tag-container">
                <span class="tag" v-if="item.categoryName" >{{ item.categoryName }}</span>
                <span v-if="!item.categoryName"></span>
                <!--删除按钮 -->
<!--                <svg-->
<!--                  v-if="myVideoListTag"-->
<!--                  class="icon-button"-->
<!--                  @click="deleteMyVideo(item.vusrId, index)"-->
<!--                >-->
<!--                  <use xlink:href="/img/svg/video.svg#icon-delete"></use>-->
<!--                </svg>-->
                <icon-svg v-if="myVideoListTag" iconClass="icontrash-2"  @click="deleteMyVideo(item.vdId, index)" class="icon-button delete-button"/>
              </div>
            </div>
          </div>
          <div class="my_video_list" v-if="myVideoListTag">
            <div>
              <icon-svg iconClass="iconfile-text" class=""/><span
                >线索 {{ item.countOfClues }}</span
              >
            </div>
            <div>
              <icon-svg iconClass="iconeye" class=""/><span
                >查看 {{ item.viewsNumber }}</span
              >
            </div>
            <div>
              <icon-svg iconClass="iconshare" class=""/><span
                >转发 {{ item.forwardingNumber }}</span
              >
            </div>
          </div>
        </van-cell>
      </div>
    </van-list>

    <!-- 弹出分享框 -->
    <div class="share_box">
      <div class="wrapperPoster" id="saveposter" style="z-index: 9999" v-if="showOverlay">
        <div class="poster-details" v-if="showImg" ref="imageWrapper">
          <img :src="posterPic" crossOrigin="anonymous"/>
          <div class="text-qrcode">
            <div class="user-info-tag">
              <div class="user-info">
                <img :src="headImgUrl">
                <div class="left-text">
                  <p>{{ userForm.name }}</p>
                  <p>{{ userForm.merchantName }}</p>
                </div>
              </div>
              <div class="user-tag">
                <span class="user-tag-item" v-for="(it,index) in userTags" :key="index">{{ it }}</span>
              </div>
            </div>
            <div class="qrCodeUrl-all">
              <div class="qrCodeUrl-cls">
                <img v-if="qrCodeUrl" :src="qrCodeUrl"/>
              </div>
              <p>立即扫码</p>
              <p>了解更多内容</p>
            </div>
          </div>
        </div>
        <div v-else class="posterImg-hb">
          <img class="card-img" :src="imgSrc" ref="cardImgRef" />
        </div>
      </div>
      <van-share-sheet
        v-model:show="showShare"
        title="分享"
        :options="shareOptions"
        @select="onSelectShare"
        cancel-text="取消"
        safe-area-inset-bottom
      >
      </van-share-sheet>
    </div>

  </div>
</template>

<script>
import {nextTick, onMounted, reactive, ref, toRefs, watch} from "vue";
import {useRoute, useRouter} from "vue-router";
import { Dialog, Toast } from "vant";
import { flutterShowToast } from "@/utils/appChannel";
import {
  recordUserVideoShareInfo,
  deleteUserVideoShareInfo,
        getVideoDetailsUrl
} from "../../../api/video";
import {createPosterAppShare,getQrCodeUrl} from "@/api/common";
import { loadImages } from "@/utils/commonUtil";
import {posterShare} from "@/utils/posterShare"
import html2canvas from "html2canvas";
import  {findPosterImg} from "@/api/file"
import {getUserCardByPoster} from "@/api/user/userCard"

export default {
  props: {
    videoList: Array,
    videoCategory: Number,
    myVideoListTag: Boolean,
    addPoster:String,
  },
  setup(props, context) {
    const imageWrapper = ref(null);
    const cardImgRef = ref(null);
    const route = useRoute()
    const state = reactive({
      userForm:{},
      userTags:[],
      headImgUrl:'',
      showSharePoster:false,
      shareJumpUrl:'',
      posterId:'',
      imgSrc:'',
      qrCodeUrl:'',
      posterName:'',
      posterPic:'',
      sharePosterImg:'',
      showOverlay: false,
      showImg: true,
      shareOptions:[],
      defaultImg: require("@/assets/images/videoListDefaultCover.png"),
      imgUrl: process.env.VUE_APP_WATCH_FILE_API,
      //=======================
      fileHost: process.env.VUE_APP_WATCH_FILE_API + "/",
      number: 1,
      showShare: false,
      options: [
        { name: "微信", icon: require("@/assets/icons/weChat2.png") },
        { name: "朋友圈", icon: require("@/assets/icons/friends2.png") },
        { name: "复制链接", icon: require("@/assets/icons/copy-link.png") },
        { name: "分享海报", icon: require("@/assets/icons/share_poster.png")}
      ],
      posterOptions: [
        {name: "微信分享", icon: require("@/assets/icons/weChat2.png")},
        {name: "朋友圈分享", icon: require("@/assets/icons/friends2.png")},
      ],
      shareVdId: 0,
      videoItem: {},
    });
    watch(() => state.showShare, (newVal, oldVal) => {
      console.log('新值：',newVal,'老值',oldVal)
      if (state.showShare===false){
        state.showOverlay = false
        state.showImg = true
      }
    })
    //分享视频
    const loadShow = (item) => {
      // console.log("分享的视频",item)
      // state.showShare = true;
      // console.log("视频id", item);
      state.shareVdId = item.vdId;
      state.videoItem = item;
      state.posterName = item.name;
      getUserCardByPoster().then(res=>{
        if (res){
          if (res.headImgUrl){
            state.headImgUrl = state.imgUrl+'/'+res.headImgUrl
          }else {
            state.headImgUrl = require("@/assets/poster_headerImg_default.png")
          }
          state.userForm = res
          state.userTags = res.impressionLabel.split(',')
          if (state.userTags.length>4){
            state.userTags = state.userTags.slice(0,4)
          }
        }
      })
      let params = {
        sourceId:state.shareVdId,//分享的id
        sourceType:3,//来源类别 1-文章 2-案例 3-视频 4-工地
      }
      const p = {
        busiId:item.vdId,
        busiCat:'poster',
        groupId:'sharePosterPic',
      }
      findPosterImg(p).then((res)=>{
        console.log("海报图片==============》",res)
        if (res.length>0){
          //获取小图
          findPosterImg({
            busiId:item.vdId,
            busiCat:'poster',
            groupId:'sharePosterPic:S',
          }).then((sImg)=>{
            state.sharePosterImg = sImg[0].url?state.imgUrl+"/"+sImg[sImg.length-1].url:"https://wca.ujiaku.com/upload/wx/videoListDefaultCover.png"
          })
          state.posterPic = res[0].url?state.imgUrl+"/"+ res[0].url:state.defaultImg
        }else {
          state.posterPic = state.defaultImg
          state.sharePosterImg = "https://wca.ujiaku.com/upload/wx/videoListDefaultCover.png"
        }
        state.showShare = true;
      })
      getQrCodeUrl(params).then((res)=>{
        state.shareJumpUrl = res.jumpUrl
        if(res.qrCodeUrl){
          state.qrCodeUrl = res.qrCodeUrl
        }
        state.showSharePoster = true
      })
      state.shareOptions = state.options
      // state.posterPic = item.videoCoverUrl?state.imgUrl+"/"+item.videoCoverUrl:state.defaultImg
      // state.sharePosterImg = item.videoCoverUrl?state.imgUrl+"/"+item.smallCoverPic:"https://wca.ujiaku.com/upload/wx/videoListDefaultCover.png"
    };
    console.log("所有视频", props.videoList);
    const router = useRouter();
    const loadVideo = (id) => {
      console.log("视频的路由参数",route.params)
      if(route.params.addPoster==='true'){
        router.push({
          path: `/video/videoDetail/${id}`,
          query:{
            addPoster:true,
            fromPoster:false
          },
        });
        return
      }
      if(route.params.fromPoster==='true'){
        router.push({
          path: `/video/videoDetail/${id}`,
          query:{
            addPoster:false,
            fromPoster:true
          },
        });
        return
      }
      router.push({
        path: `/video/videoDetail/${id}`,
        query:{
          addPoster:false,
          fromPoster:false
        }
      });
    };

    //删除我的视频
    const deleteMyVideo = (vdId, index) => {
      console.log("===delete vdId===", vdId);
      Dialog.confirm({
        title: "",
        message: "是否删除?",
      })
        .then(() => {
          //正在删除图标
          Toast.loading({
            message: "删除中...",
            // forbidClick: true,
            duration: 20000,
            loadingType: "spinner",
          });
          deleteUserVideoShareInfo({ vdId: vdId }).then((data) => {
            Toast.clear()
            if (data) {
              context.emit("delete_my_video", index);
            }
          }).catch(()=>{
            console.log("===删除失败===")
            Toast.clear()
          });
        })
        .catch(() => {
          // on cancel
        });
    };
    const loadPoster = ()=>{
      savePoster()
      setTimeout(()=>{
        Toast.clear()
      })
    }
    //选择分享菜单
    const onSelectShare = (option) => {
      let shareType;
      let content;
      let merchantId = localStorage.getItem('merchantId')
      if (option.name === "微信") {
        shareType = "shareToMiniProgram";
        content =  `${process.env.VUE_APP_WECHAT_SHARE}/video/videoDetail/${state.videoItem.vdId}/123/1/${merchantId}`
        let param = {
          miniProgramType:state.videoItem.jumpApplets,
          shareType: shareType,
          urlEntity: {
            title: `${process.env.VUE_APP_WECHAT_SHARE}/shareForward?shareUrl=`,
            content: content,
          },
          title: state.videoItem.name,
          shareImg: state.videoItem.smallCoverPic?`${process.env.VUE_APP_WATCH_FILE_API}/${state.videoItem.smallCoverPic}`:"https://wca.ujiaku.com/upload/wx/videoListDefaultCover.png"
        };
        console.log(param);
        flutterShowToast(param);
        state.showShare = false;
        recordUserVideoShareInfo({ vdId: state.shareVdId });
      }else if(option.name === "朋友圈"){
        shareType = "shareH5PageToTimeLine";
        content =  `${process.env.VUE_APP_WECHAT_SHARE}/video/videoDetail/${state.videoItem.vdId}/123/2/${merchantId}`
        let param = {
          miniProgramType:state.videoItem.jumpApplets,
          shareType: shareType,
          urlEntity: {
            title: `${process.env.VUE_APP_WECHAT_SHARE}/shareForward?shareUrl=`,
            content: content,
          },
          title: state.videoItem.name,
          shareImg: state.videoItem.smallCoverPic?`${process.env.VUE_APP_WATCH_FILE_API}/${state.videoItem.smallCoverPic}`:"https://wca.ujiaku.com/upload/wx/videoListDefaultCover.png"
        };
        console.log(param);
        flutterShowToast(param);
        state.showShare = false;
        recordUserVideoShareInfo({ vdId: state.shareVdId });
      }else if(option.name === "复制链接") {
        getVideoDetailsUrl(state.shareVdId).then((res) => {
          let param = {
            shareType: "copyText",
            desc: res
          };
          flutterShowToast(param)
          console.log(res)
          Toast.success("复制成功")
          state.showShare = false
        })
        // return
      }else if(option.name === "分享海报"){
        if(state.showSharePoster===true){
          Toast.loading({
            message: '生成海报中...',
            forbidClick: true,
            duration:0,
            className:'posterToast',
          });
          state.showOverlay = true;
          state.shareOptions = []
          state.shareOptions = state.posterOptions
          // setTimeout(()=>{
          //   savePoster()
          // },200)
          let arr = []
          arr.push(state.posterPic)
          arr.push(state.qrCodeUrl)
          arr.push(state.headImgUrl)
          loadImages(arr,loadPoster)
      }
        // return;
      }else if(option.name === "微信分享"||option.name === "朋友圈分享"){
        if(state.showSharePoster===true){
          Toast.loading({
            message: '正在\n打开微信...',
            forbidClick: true,
            duration:3000,
            className:'posterToast',
          });
          let p = {
            sourceId: state.shareVdId,//分享的id
            sourceType:3,//来源类别 1-文章 2-案例 3-视频 4-工地
            jumpUrl:state.shareJumpUrl
          }
          createPosterAppShare(p).then((res)=>{
            console.log("创建海报",res)
          })
          let param = {
            imgSrc:state.imgSrc,
            id:state.shareVdId,
            bigPosterImgUrl:state.sharePosterImg,
          }
          setTimeout(()=>{
            posterShare(option,param)
          })
        }
        // return;
      }

      // let param = {
      //   miniProgramType:state.videoItem.jumpApplets,
      //   shareType: shareType,
      //   urlEntity: {
      //     title: `${process.env.VUE_APP_WECHAT_SHARE}/shareForward?shareUrl=`,
      //     content: content,
      //   },
      //   title: state.videoItem.name,
      //   shareImg: state.videoItem.smallCoverPic?`${process.env.VUE_APP_WATCH_FILE_API}/${state.videoItem.smallCoverPic}`:null
      // };
      // console.log(param);
      // flutterShowToast(param);
      // state.showShare = false;
      // recordUserVideoShareInfo({ vdId: state.shareVdId });
    };

    onMounted(() => {});
    //画图
    const savePoster = () =>{
      state.showOverlay = true;
      // html2canvasChange()
      nextTick(() => {
        console.log("nextTick->生成海报================")
        // var canvas2 = document.createElement("canvas");
        let _canvas = imageWrapper.value;
        const box = window.getComputedStyle(_canvas);
        // DOM 节点计算后宽高
        const width = parseInt(box.width, 10);
        const height = parseInt(box.height, 10);
        // 获取像素比
        const scaleBy = DPR();
        // 创建自定义 canvas 元素
        const canvas = document.createElement("canvas");
        // 设定 canvas 元素属性宽高为 DOM 节点宽高 * 像素比
        canvas.width = width * scaleBy;
        canvas.height = height * scaleBy;
        // 设定 canvas css宽高为 DOM 节点宽高
        canvas.style.width = `${width}px`;
        canvas.style.height = `${height}px`;
        // console.log("视频海报滚动条高度",)
        // console.log("滚动条高度============",document.getElementById('saveposter').scrollHeight)
        html2canvas(_canvas, {
          useCORS: true,
          background: "#ffffff",
          // y:document.body.scrollTop,
          // height: document.getElementById('saveposter').scrollHeight,
          windowHeight: document.getElementById('saveposter').scrollHeight,
          canvas,
        }).then((canvas) => {
          var context = canvas.getContext("2d");
          context.mozImageSmoothingEnabled = false;
          context.webkitImageSmoothingEnabled = false;
          context.msImageSmoothingEnabled = false;
          context.imageSmoothingEnabled = false;
          let dataURL = canvas.toDataURL("image/png");
          state.showImg = false
          nextTick(() => {
            cardImgRef.value.style.width = width + "px";
            cardImgRef.value.style.height = height + "px";
            state.imgSrc = dataURL;
            state.showImg = false
          });
        });
      });
    }
    const DPR = () => {
      if (window.devicePixelRatio && window.devicePixelRatio > 1) {
        return window.devicePixelRatio;
      }
      return 1;
    };

    return {
      imageWrapper,
      cardImgRef,
      savePoster,
      DPR,
      ...toRefs(state),
      loadShow,
      loadVideo,
      deleteMyVideo,
      onSelectShare,
    };
  },
};
</script>

<style lang="scss">
.posterToast{
  //color: #289fff;
  z-index: 1000000001 !important;
}
.delete-button {
    color: red;
}
.icon-button {
  flex-shrink:0;
  width: 20px;
  height: 20px;
}
.container-allvideo {
  // padding-bottom: 200px;
  flex: 1;
  .video-list {
    .van-cell {
      padding: 0;
    }

    .van-cell:first-of-type {
      .video-container {
        border-top: 0.5px solid #f1f3f6;
      }
    }

    .video-container {
      border-bottom: 0.5px solid #f1f3f6;
      text-align: center;
      padding: 8px 16px;

      .video-content {
        .video-cover {
          /*width: 343px;*/
          height: 160px;
          position: relative;
          background-color: #000000;
          img {
            width: 100%;
            height: 100%;
          }
        }

        .labelContainer {
          position: absolute;
          left: 0px;
          top: 0px;
          line-height: 18px;
        }

        .has-top {
          width: 40px;
          height: 18px;
          border-radius: 4px 0px;
          background-color: #ff6e01;
          font-family: PingFang SC;
          font-style: normal;
          font-size: 12px;
          color: #ffffff;
          text-align: center;
          display: inline-block;
          line-height: 18px;
          margin-right: 5px;
        }

        .has-material-library {
          background: #ffcf23;
          width: 52px;
          height: 18px;
          border-radius: 4px 0px;
          font-family: PingFang SC;
          font-style: normal;
          font-size: 12px;
          color: #ffffff;
          text-align: center;
          display: inline-block;
          line-height: 18px;
          margin-right: 5px;
        }

        .name {
          text-align: left;
          font-family: PingFang SC;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          /* identical to box height, or 150% */

          display: flex;
          align-items: center;
          justify-content: space-between;
          /* 字体颜色/3 */
          color: #333333;
          margin-top: 6px;
          margin-bottom: 6px;
        }

        .tag-container {
          display: flex;
          justify-content: space-between;
          .tag {
            height: 20px;
            border: 1px solid rgba(0, 0, 0, 0.3);
            box-sizing: border-box;
            border-radius: 4px;
            font-family: PingFang SC;
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 20px;
            color: #999999;
            padding: 0px 4px;
          }
        }
      }
    }

    .my_video_list {
      display: flex;
      justify-content: space-around;
      align-items: center;
      height: 34px;
      border-bottom: 1px solid #f1f3f6;
      > div {
        height: 34px;
        display: flex;
        color: #999999;
        align-items: center;
      }
    }
  }

  .share_box {
    .van-share-sheet__cancel::before {
      height: 0;
    }
    .van-share-sheet__cancel {
      height: 48px;
      width: 343px;
      background-color: #f7f7f7;
      color: #333333;
      border-radius: 5px;
      //    position: relative;
      //    transform: translateX(50%);
      margin-left: 15px;
      margin-bottom: 15px;
      margin-top: 15px;
    }
    .van-share-sheet__options {
      color: #333333;
      display: flex;
      padding: 0 20px;
      justify-content: space-around;
    }

  }
  .wrapperPoster{
    //margin-top: 100px;
    height: 400px;
    overflow-x: hidden;
    //position: absolute;
    width: auto;
    position: fixed;
    top: 60px;
    //bottom: 0;
    left: 0;
    right: 0;
    .poster-details{
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      width: 250px;
      overflow-x: hidden;
      >img{
        width: 250px;
        display: block;
      }
      //.text-qrcode{
      //  background-color: #ffff;
      //  display: flex;
      //  justify-content: space-between;
      //  padding: 10px;
      //  .left-text{
      //    text-align: left;
      //    p {
      //      margin: 0;
      //      padding: 0;
      //    }
      //    p:nth-child(1){
      //      font-size: 14px;
      //      font-weight: 400;
      //      line-height: 18px;
      //      color: #333333;
      //    }
      //    p:nth-child(2){
      //      font-size: 9px;
      //      color: #999999;
      //      line-height: 20px;
      //    }
      //  }
      //  .qrCodeUrl-all{
      //    position: relative;
      //    text-align: center;
      //    height: 60px;
      //    .qrCodeUrl-cls{
      //      width: 60px;
      //      height: 60px;
      //      background-color: #FFFFFF;
      //      border-radius: 30px;
      //      position: relative;
      //      top: -33px;
      //      text-align: center;
      //      img{
      //        width: 50px;
      //        border-radius: 25px;
      //        position: relative;
      //        margin-top: 50%;
      //        transform: translateY(-50%);
      //      }
      //    }
      //    >p{
      //      position: relative;
      //      font-size: 9px;
      //      font-weight: 400;
      //      color: #999999;
      //      top: -40px;
      //    }
      //  }
      //}
      .text-qrcode{
        background-color: #ffff;
        display: flex;
        justify-content: space-between;
        padding: 10px;
        flex: 1;
        .user-info-tag{
          .user-info{
            display: flex;
            align-items: center;
            img{
              border-radius: 20px;
              width: 40px;
              height: 40px;
              object-fit: cover;
              flex-shrink: 0;
            }
            .left-text{
              text-align: left;
              //flex: 1;
              margin-left: 4px;
              p {
                margin: 0;
                padding: 0;
              }
              p:nth-child(1){
                font-size: 16px;
                font-weight: 400;
                line-height: 18px;
                color: #333333;
              }
              p:nth-child(2){
                font-size: 10px;
                color: #333333;
                //line-height: 20px;
              }
            }
          }
          .user-tag{
            display: flex;
            flex-wrap: wrap;
            .user-tag-item{
              margin-top: 5px;
              margin-right: 5px;
              //padding-left: 5px;
              padding:2px 5px;
              color: #289FFF;
              background-color: #E6ECFF;
              font-size: 9px;
              border-radius: 4px;
            }
          }
        }


        .qrCodeUrl-all{
          //position: relative;
          //text-align: center;
          //height: 60px;
          border-left: 1px solid #F1F3F6;
          padding-left: 18px;
          margin-left: 8px;
          flex-shrink:0;
          .qrCodeUrl-cls{
            //width: 60px;
            //height: 60px;
            background-color: #FFFFFF;
            border-radius: 30px;
            //position: relative;
            //top: -33px;
            //text-align: center;
            img{
              width: 60px;
              //border-radius: 25px;
              //position: relative;
              //margin-top: 50%;
              //transform: translateY(-50%);
            }
          }
          >p{
            //position: relative;
            text-align: center;
            font-size: 10px;
            font-weight: 400;
            color: #666666;
            margin: 0;
            //top: -40px;
          }
        }
      }
    }
    .posterImg-hb{
      height: 400px;
      overflow-x: hidden;
    }
  }
}
</style>
